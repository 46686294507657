<template>
  <div class="page-vacations-root">
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <!-- Card stats -->
    </base-header>

    <b-container fluid class="mt--7">
      <b-row>
        <b-col>
          <b-overlay :show="loading" rounded="mdd">
            <b-card no-body>
              <b-card-header class="border-0">
                <h3 class="mb-0 d-inline">{{ pageName }}</h3>
                <div class="d-inline float-right mt--1" style="font-size: 28px; height: 28px; cursor: pointer;">
                  <b-icon
                    id="id-prev-year"
                    icon="chevron-left"
                    class="rounded-circle bg-info p-2 mr-2"
                    variant="light"
                    @click="toPrevYear"
                  ></b-icon>
                  <b-icon
                    id="id-next-year"
                    icon="chevron-right"
                    class="rounded-circle bg-info p-2 "
                    variant="light"
                    @click="toNextYear"
                  ></b-icon>
                </div>
                <b-tooltip target="id-prev-year" triggers="hover">
                  昨年
                </b-tooltip>
                <b-tooltip target="id-next-year" triggers="hover">
                  来年
                </b-tooltip>
              </b-card-header>
              <div class="p-2 pb-4 calendar-wrapper">
                <b-calendar
                  v-for="(item, key) in months"
                  :key="`${year}_${key}`"
                  :min="item.min"
                  :max="item.max"
                  :hide-header="true"
                  :initial-date="item.initialDate"
                  :date-info-fn="getVacationCategory"
                  :disabled="true"
                  label-help=""
                />
              </div>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
      <b-row class="mt-3" id="vacations_histories">
        <b-col>
          <b-overlay :show="loadingAll" rounded="mdd">
            <b-card no-body>
              <b-card-header class="border-0">
                <h3 class="mb-0 d-inline">すべての休暇履歴</h3>
              </b-card-header>
              <b-table
                :items="allVacations"
                :fields="fields"
                stacked="md"
                show-empty
                small
                hover
              >
                <template #cell(file_uuid)="row">
                  <a href="javascript:void(0)" @click="previewPdf(row.value)">
                    {{ row.item.file_name }}
                  </a>
                </template>
                <template #cell(actions)="row">
                  <b-button
                    size="sm" 
                    variant="link"
                    v-b-modal.modal-undo-vacation
                    @click="formData.vacation = row.item"
                  >
                    <b-icon icon="trash" variant="primary"></b-icon>
                  </b-button>
                </template>
              </b-table>
              <b-card-footer>
              </b-card-footer>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
      <b-modal
        id="modal-undo-vacation"
        title="休暇申請を取消"
        @hidden="resetModal"
        @ok="cancelVacation"
      >
        <validation-observer ref="observer">
          <base-input
            label="休暇内容"
            :value="vacationSummary"
            :readonly="true"
          />
          <base-textarea
            label="取消の理由"
            :rules="{ required: true }"
            v-model="formData.reason"
            :error="formData.error"
          />
        </validation-observer>
      </b-modal>
    </b-container>
  </div>
</template>
<script>
import { Toast } from '../../components';
import { ajax, common, config } from '../../utils'
export default {
  data() {
    return {
      loading: false,
      loadingAll: false,
      year: parseInt(common.getCurrentYear()),
      vacations: [],
      allVacations: [],
      formData: {
        vacation: {},
        reason: null,
        error: null,
      }
    }
  },
  mounted() {
    this.getVacations(this.year);
    this.getAllVacations();
  },
  computed: {
    months() {
      return Array.from(Array(12), (v, k) => k).map(i => ({
        "min": new Date(this.year, i, 1),
        "max": new Date(this.year, i + 1, 0),
        "initialDate": new Date(this.year, i, 1),
      }));
    },
    pageName: function() {
      return `${this.year}年の${common.getPageName(this.$route.name)}`;
    },
    fields() {
      return [
        { key: 'category_name', label: '休暇種類' },
        { key: 'start_date', label: '開始日' },
        { key: 'end_date', label: '終了日' },
        { key: 'days', label: '日数' },
        { key: 'actions', label: 'Actions', class: 'text-center' },
      ];
    },
    vacationSummary() {
      const { vacation } = this.formData;
      if (vacation.id) {
        return `${vacation.category_name}:${vacation.days}日(${vacation.start_date}~${vacation.end_date})`;
      }
      return null;
    },
  },
  methods: {
    toPrevYear() {
      this.year -= 1;
      this.getVacations(this.year);
    },
    toNextYear() {
      this.year += 1;
      this.getVacations(this.year);
    },
    getVacations(year) {
      this.loading = true;
      ajax.fetchGet(common.formatStr(config.api.employee.vacations, year)).then(data => {
        this.vacations = data;
      }).finally(() => {
        this.loading = false;
      })
    },
    getAllVacations() {
      this.loadingAll = true;
      ajax.fetchGet(config.api.employee.vacations_all).then(data => {
        this.allVacations = data;
      }).finally(() => {
        this.loadingAll = false;
      })
    },
    getVacationCategory(ymd, date) {  // eslint-disable-line
      for (const vacation of this.vacations) {
        if (ymd >= vacation.start_date && ymd <= vacation.end_date) {
          let tail = "all";
          if (vacation.is_start_half && ymd === vacation.start_date) {
            tail = "pm"
          } else if (vacation.is_end_half && ymd === vacation.end_date) {
            tail = "am"
          }
          return `category-${vacation.category}-${tail}`;
        }
      }
      return "";
    },
    resetModal() {
      this.formData = {
        vacation: {},
        reason: null,
        error: null,
      }
    },
    async cancelVacation(bvModalEvt) {
      bvModalEvt.preventDefault();
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return
      }
      common.loading();
      const { vacation, reason } = this.formData
      ajax.fetchPost(config.api.task.undo_vacation, {
        "vacation_id": vacation.id,
        "summary": this.vacationSummary,
        "comment": reason,
      }).then(data => {
        this.$router.push({ name: "task-unresolved-detail", params: { id: data.id } });
      }).catch(errors => {
        if (errors.comment) {
          this.formData.error = errors.comment;
        } else {
          Toast.errorFromApi(errors);
        }
      }).finally(() => {
        common.loaded();
      });
    },
  }
}
</script>
